import { Alert, Button, Dialog, DialogActions, DialogTitle, Modal, Snackbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createContext, useEffect, useState } from "react"
import loader from 'assets/images/back-trans.svg';
import useJwt from "utils/jwt/useJwt";
import { useSelector } from "react-redux";

const LoaderContext = createContext()
let confirmCallback = null;
let holdingLoaded = false;
let commissionLoaded = false;
let inboxLoaded = false
let isLoading = false;
let isInboxLoading = false;
const images = [];

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: '#000',
    outline: 0
  },
}));

const ProgressLoader = ({ children }) => {

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState('');
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState({
    text: "",
    type: "error",
  });
  const auth = useSelector((state) => state.auth);

  const showProgress = () => {
    setOpen(true);
  };

  const hideProgress = () => {
    setOpen(false);
  };

  const showToast = (type, message) => {
    setSnackBarMsg({
      text: message,
      type: type,
    });
    setSnackBarOpen(true);
  }

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  };

  const showConfirmDialog = (message, callback) => {
    setConfirmDialogOpen(true);
    setConfirmDialogMessage(message);
    confirmCallback = callback;
  }

  const handleConfirm = () => {
    setConfirmDialogOpen(false);
    if (confirmCallback) {
      confirmCallback();
      confirmCallback = null;
    }
  }

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  }


  ////////////////////////////////////////////////
  /////////////////// Image //////////////////////
  ////////////////////////////////////////////////

  const addImage = (url, data) => {
    images.push({ url, data })
  }

  const getImage = (url) => {
    for (const image of images) {
      if (image.url === url) {
        return image.data
      }
    }
    return null;
  }


  ////////////////////////////////////////////////////
  /////////////////// configurations /////////////////
  ////////////////////////////////////////////////////
  const [configurations, setConfigurations] = useState({
    fee_sales_gold_coins: 0,
    fee_sales_gold_bars: 0,
    fee_year_gold_store_manage: 0,
    fee_sales_silver_coins: 0,
    fee_sales_silver_bars: 0,
    fee_year_silver_store_manage: 0,
    email_footer: ''
  });

  const loadConfigurations = () => {
    useJwt
    .loadSettingsInfo()
    .then((res) => {
      if (res.data.ResponseCode == 0) {
        setConfigurations(res.data.ResponseResult);
      } else {
        console.log(res.data.ResponseCode);
      }
    })
    .catch((err) => {
      console.log(err);
    });
  }

  ////////////////////////////////////////////////////
  /////////////////// Portfolio //////////////////////
  ////////////////////////////////////////////////////
  const [holdingData, setHoldingData] = useState([]);

  const loadHoldingData = () => {
    if (isLoading) return;
    isLoading = true;

    if (!holdingLoaded) {
      showProgress();
    }

    useJwt
      .loadAdvisorOverview(holdingLoaded ? 0 : 1)
      .then((res) => {
        //console.log(res,"holdingLoaded")
        isLoading = false;
        hideProgress();
        if (res.data.ResponseCode == 0) {
          // setHoldingData(res.data.ResponseResult);
          setHoldingData(res.data.ResponseResult.filter(obj=>{
            for(let key in obj){
              if(Object.prototype.hasOwnProperty.call(obj,key)){
                return true;
              }
            }
            return false;
          }))
        }
        else {
          showToast('error', res.data.ResponseMessage);
          console.log(res.data.ResponseCode);
        }
      })
      .catch((err) => {
        isLoading = false;
        hideProgress();
        showToast('error', 'Network error');
        console.log("err", err);
      });

    if (!holdingLoaded) {
      holdingLoaded = true;
    }
  };


  ////////////////////////////////////////////////////
  /////////////////// Portfolio //////////////////////
  ////////////////////////////////////////////////////
  const [commissionData, setCommissionData] = useState({});

  const loadCommissionData = () => {
    if (!commissionLoaded) {
      showProgress();
    }

    useJwt
      .loadCommissionData(commissionLoaded ? 0 : 1)
      .then((res) => {
        hideProgress();
        if (res.data.ResponseCode == 0) {
          setCommissionData(res.data.ResponseResult)
        }
        else {
          showToast('error', res.data.ResponseMessage);
          console.log(res.data.ResponseCode);
        }
      })
      .catch((err) => {
        hideProgress();
        showToast('error', 'Network error');
        console.log("err", err);
      });

    if (!commissionLoaded) {
      commissionLoaded = true;
    }
  };



  ////////////////////////////////////////////////
  /////////////////// Email //////////////////////
  ////////////////////////////////////////////////
  const [inboxMails, setInboxEmails] = useState([]);
  const [unreadMails, setUnreadEmails] = useState([]);
  const [sentMails, setSentEmails] = useState([]);
  const [draftMails, setDraftEmails] = useState([]);
  const [trashMails, setTrashEmails] = useState([]);

  const setInboxLoaded = (value) => {
    inboxLoaded = value
    if (!value) {
      setUnreadEmails([])
    }
  }

  const loadInbox = () => {
    if (!inboxLoaded) {
      inboxLoaded = true
      console.log('load inbox');
      updateMailInbox(true)
      loadSentMails()
      loadDraftMails()
      loadTrashMails()

      const timerId = setInterval(() => {
        updateMailInbox(false);
      }, 300000);

      // return () => {
      //   clearInterval(timerId);
      // };
    }
    else {
      updateMailInbox(false);
    }
  }

  const setMailReadUnread = (mailId, status) => {
    let items = [...inboxMails];
    let index = -1
    let mail = null;

    for (let i = 0; i < inboxMails.length; i++) {
      const item = inboxMails[i];
      if (item.id == mailId) {
        index = i;
        mail = item;
        break;
      }
    }

    if (mail) {
      let itemMail = {...mail};
      itemMail.status = status
      items[index] = itemMail
      setInboxEmails(items)

      if (status === 1) {
        const tums = unreadMails.filter(item => !(item.id == mailId));
        setUnreadEmails([...tums]);
      }
    }
  }

  const updateMailInbox = (showLoading) => {
    if (!auth.userData) {
      return
    }
    if (isInboxLoading) {
      return
    }
    isInboxLoading = true

    if (showLoading) {
      showProgress()
    }

    useJwt.getMailInbox().then((res) => {
      isInboxLoading = false
      if (!isLoading) hideProgress();

      if (res.data.ResponseCode == 0) {
        const mails = res.data.ResponseResult
        setInboxEmails(mails);

        const ums = [];
        for (let mail of mails) {
          if (mail.status == 0) {
            ums.push(mail);
          }
        }
        setUnreadEmails(ums);
      }
      else {
        showToast('error', res.data.ResponseMessage);
      }
    })
    .catch(err => {
      isInboxLoading = false
      if (!isLoading) hideProgress();
      showToast('error', 'Network error');
    });
  };

  const loadSentMails = () => {
    useJwt.getMailSent().then((res) => {
      if (res.data.ResponseCode == 0) {
        setSentEmails(res.data.ResponseResult);
      }
      else {
        showToast('error', res.data.ResponseMessage);
      }
    });
  }

  const loadDraftMails = () => {
    useJwt.getMailDraft().then((res) => {
      if (res.data.ResponseCode == 0) {
        setDraftEmails(res.data.ResponseResult);
      }
      else {
        showToast('error', res.data.ResponseMessage);
      }
    });
  }

  const loadTrashMails = () => {
    useJwt.getMailTrash().then((res) => {
      if (res.data.ResponseCode == 0) {
        setTrashEmails(res.data.ResponseResult);
      }
      else {
        showToast('error', res.data.ResponseMessage);
      }
    });
  }

  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState([]);

  return (
    <LoaderContext.Provider value={{
      showProgress,
      hideProgress,
      showToast,
      showConfirmDialog,
      configurations,
      loadConfigurations,
      addImage,
      getImage,
      holdingData,
      loadHoldingData,
      commissionData,
      loadCommissionData,
      setInboxLoaded,
      inboxMails,
      sentMails,
      draftMails,
      trashMails,
      setInboxEmails,
      setSentEmails,
      setDraftEmails,
      setTrashEmails,
      loadInbox,
      updateMailInbox,
      loadSentMails,
      loadDraftMails,
      loadTrashMails,
      setMailReadUnread,
      unreadMails,
    }}>{children}
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
      >
        <div className={classes.paper}>
          <img src={loader} alt="" style={{ width: '100px', height: '100px' }} />
        </div>
      </Modal>
      <Dialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {confirmDialogMessage}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleConfirm();
            }}
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity={snackBarMsg.type}
          sx={{ width: "100%" }}
        >
          {snackBarMsg.text}
        </Alert>
      </Snackbar>
    </LoaderContext.Provider>
  );
};

export { LoaderContext, ProgressLoader }
